<template>
    <b-card title="Deleted Profiles">
  
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
            <!-- <b-form-group v-if="this.showAdd">
                <div class="d-flex align-items-center">
                    <b-button
                        id="toggle-btn"
                        v-b-modal.modal-prevent-closing
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="addNew()"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add New</span>
                    </b-button>
                </div>
            </b-form-group> -->
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
        </div>
    
        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm 
            }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            styleClass="vgt-table striped condensed no-outline"
        >
    
            <template
                slot="table-row"
                slot-scope="props"
            >
    
                <!-- Column: Name -->
                <span
                    v-if="props.column.field === 'fullName'"
                    class="text-nowrap"
                >
                    <router-link :to="{ path: '/admin/user/profile/' + props.row.personal_info.profile_id }">
                        <span class="text-nowrap">{{ props.row.user_object.first_name }} {{ props.row.user_object.last_name }}</span>
                    </router-link>
                </span>
                
                <!-- Column: Index -->
                <span v-else-if="props.column.field === 'index'">
                    <span>{{props.row.originalIndex+1}}</span>
                </span>

                <!-- Column: Free/Premium -->
                <span v-else-if="props.column.field === 'premium_status'">
                    <span v-if="props.row.personal_info.premium_status === 'premium'" @click="updatePremiumStatus(props.row.personal_info.profile_id, 'regular')" style="cursor:pointer"><b-badge pill variant="light-success">Premium</b-badge></span>
                    <span v-else @click="updatePremiumStatus(props.row.personal_info.profile_id, 'premium')" style="cursor:pointer"><b-badge pill variant="light-info">Regular</b-badge></span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'profile_status'">
                    <span @click="statusModal(props.row)" id="toggle-btn"
                        v-b-modal.modal-prevent-closing-1
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'" 
                        variant="outline-primary" 
                        style="cursor:pointer">
                        <span v-if="props.row.personal_info.profile_status == 'active'"><b-badge pill variant="light-success">{{props.row.personal_info.profile_status}}</b-badge></span>
                        <span v-else> <b-badge pill variant="light-danger">{{props.row.personal_info.profile_status}}</b-badge></span>
                    </span>
                </span>

                <!-- Column: Action -->
                <!-- <span v-else-if="props.column.field === 'action'">
                    <span>
                        <span>
                            <b-button @click="edit(props.row)"
                                v-b-modal.modal-prevent-closing
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="primary" class="btn-icon rounded-circle mr-1"
                                v-if="showEdit"
                            >
                                <feather-icon
                                    icon="Edit2Icon"
                                />
                            </b-button>
                            <b-button @click="deleteType(props.row)"
                                id="delete-btn"
                                v-b-modal.modal-delete
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="primary" class="btn-icon rounded-circle"
                                v-if="showDelete"
                            >
                                <feather-icon
                                    icon="TrashIcon"
                                />
                            </b-button>
                        </span>
                    </span>
                </span> -->
        
                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
    
            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['3','5','10']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>

        <!-- Update Status Modal  -->
        <b-modal
            v-if="updateStatusMode"
            id="modal-prevent-closing-1"
            ref="my-modal"
            title="Update Account Status"
            centered
            ok-title="Submit"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleStatus"
        >
            <form
                ref="form"
                @submit.stop.prevent=""
            >
    
                <b-form-group
                    label="Status"
                    label-for="fh-status"
                    md="6"
                >
                    <v-select
                        id="fh-status"
                        v-model="status.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statuses"
                        :reduce="name=>name.value"
                        label="name"
                    />
                </b-form-group>
                <b-form-group
                    class="mt-3"
                    :state="nameState"
                    label="Feedback"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                >
                    <b-form-textarea
                        id="name-input"
                        v-model="modify.name"
                        :state="nameState"
                        required
                    />
                </b-form-group>
            </form>
        </b-modal>
    
        <b-modal
            id="modal-prevent-closing"
            ref="my-modal"
            :title="getModalTitle()"
            centered
            ok-title="Submit"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form
                ref="form"
                @submit.stop.prevent="handleSubmit"
            >
    
                <b-form-group
                    label="Country"
                    label-for="fh-area"
                    md="6"
                    v-if="addNewMode"
                >
                    <v-select
                        id="fh-country"
                        v-model="modify.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="country"
                        :reduce="name=>name.id"
                        label="name"
                        @input="updateCountry"
                    />
                </b-form-group>
                <b-form-group
                        label-for="fh-type"
                        label="District"
                        v-if="addNewMode"
                >
                    <v-select
                        id="fh-type"
                        v-model="modify.district"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="district"
                        :reduce="name=>name.id"
                        label="name"
                    />
                </b-form-group>
                <b-form-group
                    :state="nameState"
                    label="Area"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="modify.name"
                        :state="nameState"
                        required
                    />
                </b-form-group>
            </form>
        </b-modal>
        <b-modal
            id="modal-delete"
            ref="modal-delete"
            centered
            modal-class="modal-danger"
            title="Are you sure?"
        >
            <b-card-text class="border-2">
                <span>If you delete, you won't be able to revert this action!!</span>
            </b-card-text>
    
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        @click="confirmDelete()"
                    >
                        Accept
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="float-right mr-1"
                        @click="$refs['modal-delete'].toggle('#delete-btn')"
                    >
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-modal>
    </b-card>
</template>
  
  <script>
  
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            nameState: null,
            addNewMode:false,
            updateStatusMode: false,
            popupActive:true,
            status: {
                id: "",
                status: ""
            },
            modify:{
                id: null,
                name: null,
                district: null,
                country: null
            },
            country_id:null,
            pageLength: 5,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center ',
                    thClass:'text-center'
                },
                {
                    label: 'Name',
                    field: 'fullName',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Phone',
                    field: 'personal_info.phone_number',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Email',
                    field: 'user_object.email',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Profile ID',
                    field: 'personal_info.profile_id',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Regular/Premium',
                    field: 'premium_status',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Status',
                    field: 'profile_status',
                    tdClass: 'text-center',
                    thClass:'text-center'
                }
            ],
            showAdd:true,
            showEdit:false,
            showDelete:false,
            rows: [],
            searchTerm: '',
            statuses: [ 
                {
                    name: 'OTP Pending',
                    value: 'otp_pending'
                },
                {
                    name: 'OTP Verified',
                    value: 'otp_verified'
                },
                {
                    name: 'Review',
                    value: 'review'
                },
                {
                    name: 'Further Review',
                    value: 'further_review'
                },
                {
                    name: 'Further Correction Due',
                    value: 'further_correction_due'
                },
                {
                    name: 'Checked',
                    value: 'checked'
                },
                {
                    name: 'Edit',
                    value: 'edit'
                },
                {
                    name: 'Edit Review',
                    value: 'edit_review'
                },
                {
                    name: 'Active',
                    value: 'active'
                },
                {
                    name: 'Incompatible',
                    value: 'incompatible'
                },
                {
                    name: 'Deleted',
                    value: 'deleted'
                }
            ]
        }
    },

    computed: {
        ...mapState('users', ["users","userError","userLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        users(newValue, oldValue) {
            this.rows=this.users
        }
    },

    created() {
        this.$store.dispatch('users/fetchUsersByStatus', 'deleted')
        this.rows = this.users
        let permissions=JSON.parse(localStorage.getItem('permissions'))
        //   this.showAdd=permissions.includes("add_area")
        //   this.showEdit=permissions.includes("change_area")
        //   this.showDelete=permissions.includes("delete_area")
        if(this.showEdit||this.showDelete){
            this.columns.push({
                label: 'Action',
                field: 'action',
                tdClass: 'text-center',
                thClass:'text-center',
                sortable: false,
            })
        }
    },

    methods:{
        updateCountry() {
            this.$store.dispatch('state/fetchStateByCountry',{country_id:this.country_id} )
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },

        resetModal() {
            this.nameState = null
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleStatus(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleStatusSubmit()
        },

        handleStatusSubmit() {
            this.$store.dispatch('users/updateAccountStatus', this.status).then(result=>{
                if(result.code==200) {
                    this.updateStatusMode = false
                    this.$store.dispatch('users/fetchUsersByStatus', 'deleted')
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update Status`:'Status Updated',
                    },
                })
            })
        },

        updatePremiumStatus(pid, status) { 
            this.$store.dispatch('users/updatePremiumStatus', {pid:pid, status:status}).then(result=>{
                if(result.code==200) {
                    this.$store.dispatch('users/fetchUsersByStatus', 'deleted')
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update Status`:'Status Updated',
                    },
                })
            })
        },

        async handleSubmit() {
            if (!this.checkFormValidity()) {
                return
            }
            // if(this.addNewMode){
            //     await this.$store.dispatch('area/createArea',{
            //         'country':this.modify.country,
            //         'district':this.modify.district,
            //         'name':this.modify.name
            //     }).then(result=>{
            //         this.$toast({
            //             component: ToastificationContent,
            //             position: 'top-right',
            //             props: {
            //                 title: `Success`,
            //                 icon: 'ThumbsUpIcon',
            //                 variant: 'success',
            //                 text: result,
            //             },
            //         })
            //     })
            // }else{
            //     await this.$store.dispatch('area/updateArea',this.modify).then(result=> {
            //         this.$toast({
            //             component: ToastificationContent,
            //             position: 'top-right',
            //             props: {
            //                 title: `Success`,
            //                 icon: 'ThumbsUpIcon',
            //                 variant: 'success',
            //                 text: result,
            //             },
            //         })
            //     })
            // }
            this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
            })
        },

        statusModal(status) {
            this.updateStatusMode = true;
            this.status.id = status.personal_info.profile_id
            this.status.status = status.personal_info.profile_status
        },

        edit (data) {
            this.addNewMode = false;
            this.country = data.country.id;
            this.district = data.district.id;
            this.modify.name=data.name;
            this.modify.id=data.id
            // this.popupActive=true;
        },

        initValues(){
            this.modify={
                id: null,
                name: null,
                district: null,
                country: null,
            }
            this.country_id=null
        },

        deleteType(data){
            this.modify.id=data.id
        },

        async confirmDelete(){
            await this.$store.dispatch("area/deleteUser", this.modify.id).then(result=>{
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Success`,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                        text: result,
                    },
                })
            })
            this.$nextTick(() => {
                this.$refs['modal-delete'].toggle('#delete-btn')
            })
        },

        getModalTitle(){
            return (this.addNewMode? 'Add New ' : 'Update ') +'User'
        }
    }
}
</script>
  
<style lang="scss" >
.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

.vs__dropdown-menu {
  height: 200px;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>